import Vue from 'vue'
import axios from 'axios'

axios.defaults.baseURL = '/api'

Vue.prototype.$http = {
    get(url, data) {
        data = {
            ...data,
            dateTestForInterNet: new Date()
        }
        return axios.get(url, {
            params: data
        })
    },
    post(url, data) {
        data = {
            ...data,
            dateTestForInterNet: new Date()
        }
        return axios.post(url, data)
    },
    put(url, data) {
        data = {
            ...data,
            dateTestForInterNet: new Date()
        }
        return axios.put(url, data)
    }
}
